const LANGUAGE_CONFIG = {
    EN: "en",
    VN: "vn",
    ESP: "esp",
    LANGUAGE_CODE_CONFIG: {
        VN: 1,
        EN: 2,
        ESP: 3
    },
    LANGUAGE_CODE_LOCAL: {
        VN: 'vi',
        EN: 'en',
        ESP: 'es'
    },
    MONTH_NAME_VN: {
        1: "Tháng 1",
        2: "Tháng 2",
        3: "Tháng 3",
        4: "Tháng 4",
        5: "Tháng 5",
        6: "Tháng 6",
        7: "Tháng 7",
        8: "Tháng 8",
        9: "Tháng 9",
        10: "Tháng 10",
        11: "Tháng 11",
        12: "Tháng 12",
    },
    MONTH_NAME_EN: {
        1: "January",
        2: "February",
        3: "March",
        4: "April",
        5: "May",
        6: "June",
        7: "July",
        8: "August",
        9: "September",
        10: "October",
        11: "November",
        12: "December",
    },
    MONTH_NAME_ESP: {
        1: "Enero",
        2: "Febrero",
        3: "Marzo",
        4: "Abril",
        5: "Mayo",
        6: "Junio",
        7: "Julio",
        8: "Agosto",
        9: "Septiembre",
        10: "Octubre",
        11: "Noviembre",
        12: "Diciembre",
    }
};

const WEEKDAY_VN = {
    0: 'CN',
    1: 'T2',
    2: 'T3',
    3: 'T4',
    4: 'T5',
    5: 'T6',
    6: 'T7',
}

const WEEKDAY_EN = {
    0: 'Sun',
    1: 'Mon',
    2: 'Tue',
    3: 'Wed',
    4: 'Thu',
    5: 'Fri',
    6: 'Sat',
}

const WEEKDAY_ESP = {
    0: 'Do',
    1: 'Lu',
    2: 'Ma',
    3: 'Mi',
    4: 'Ju',
    5: 'Vi',
    6: 'Sa',
}

const FULL_WEEKDAY_VN = {
    0: 'Chủ Nhật',
    1: 'Thứ hai',
    2: 'Thứ ba',
    3: 'Thứ tư',
    4: 'Thứ năm',
    5: 'Thứ sáu',
    6: 'Thứ bảy',
};

const FULL_WEEKDAY_EN = {
    0: 'Sunday',
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
};

const FULL_WEEKDAY_ESP = {
    0: 'Domingo',
    1: 'Lunes',
    2: 'Martes',
    3: 'Miércoles',
    4: 'Jueves',
    5: 'Viernes',
    6: 'Sábado',
};
const REGISTER_VN = [
    'Đăng kí số điện thoại',
    'Nhập mã xác nhận',
    'Đăng kí mật khẩu',
    'Đăng ký thông tin cá nhân',
    'Tạo tài khoản thành công'
]

const REGISTER_EN = [
    'Register phone number',
    'Enter your confirmation code',
    'Sign up for a password',
    'Register personal information',
    'Account successfully created'
]

const REGISTER_ESP = [
    'Registrar número de teléfono',
    'Ingrese su Código de Confirmación',
    'Regístrese para obtener una contraseña',
    'Registrar información personal',
    'Cuenta creada con éxito'
]

const FORGOT_PASSWORD_VN = [
  'Nhập số điện thoại',
  'Nhập mã xác nhận',
  'Nhập mật khẩu',
  'Hoàn thành'
]

const FORGOT_PASSWORD_EN = [
  'Enter your phone number',
  'Enter confirmation code',
  'Enter password',
  'Finish'
]

const FORGOT_PASSWORD_ESP = [
  'Ingrese su número telefónico',
  'Introducir el código de confirmación',
  'Introducir la contraseña',
  'Terminar'
]

const JOB_VN = [
    {key: 1, value: 'Học sinh/Sinh viên'},
    {key: 2, value: 'Nhân viên văn phòng'},
    {key: 3, value: 'Nội trợ'},
    {key: 4, value: 'Khác'},
]

const JOB_EN = [
    {key: 1, value: 'Student'},
    {key: 2, value: 'Office staff'},
    {key: 3, value: 'Housewife'},
    {key: 4, value: 'Other'},
]

const JOB_ESP = [
    {key: 1, value: 'Estudiante'},
    {key: 2, value: 'Personal de oficina'},
    {key: 3, value: 'Ama de casa'},
    {key: 4, value: 'Otro'},
]

const ACADEMIC_LEVEL_VN = [
    {key: 1, value: 'Phổ thông'},
    {key: 2, value: 'Trung cấp/Học nghề'},
    {key: 3, value: 'Cao đẳng'},
    {key: 4, value: 'Đại học/Cử nhân'},
    {key: 5, value: 'Thạc sĩ'},
    {key: 6, value: 'Tiến sĩ'},
]

const ACADEMIC_LEVEL_EN = [
    {key: 1, value: 'Universal'},
    {key: 2, value: 'Intermediate / Vocational'},
    {key: 3, value: 'College'},
    {key: 4, value: 'University / Bachelor'},
    {key: 5, value: 'Master'},
    {key: 6, value: 'Doctor'},
]

const ACADEMIC_LEVEL_ESP = [
    {key: 1, value: 'Universal'},
    {key: 2, value: 'Intermedio / Aprendizaje'},
    {key: 3, value: 'Universidad'},
    {key: 4, value: 'Universidad / Licenciatura'},
    {key: 5, value: 'Maestro'},
    {key: 6, value: 'Doctor'},
]

const STATE_EVENT_VN = [
    'Đặt giờ này',
    'Bạn đã đặt giờ này',
    'Không còn trống',
    'Ngoài giờ làm việc',
    '',
    'Đang đợi xác nhận',
    'Đặt giờ này'
]

const STATE_EVENT_EN = [
    'Set this time',
    'You have set this time',
    'No more blank',
    'Outside working hours',
    '',
    'Waiting for confirmation',
    'Set this time'
]

const STATE_EVENT_ESP = [
    'Establecer esta hora',
    'Has configurado este tiempo',
    'No más en blanco',
    'Tiempo extraordinario',
    '',
    'Esperando confirmación',
    'Establecer esta hora'
]

const ACCOUNT_TYPE_VN = {
    1: 'Tình nguyện viên',
    2: 'Người chăm sóc',
    3: 'Nhân viên xã hội',
    4: 'Huấn luyện viên',
    5: 'Bác sĩ tâm thần',
    6: 'Nhà tâm lý học',
    7: 'Bệnh nhân đã khỏi bệnh'
}

const ACCOUNT_TYPE_EN = {
    1: 'Volunteer',
    2: 'Caregiver',
    3: 'Social worker',
    4: 'Coach',
    5: 'Psychiatrist',
    6: 'Psychologist',
    7: 'Peer support'
}

const ACCOUNT_TYPE_ESP = {
    1: 'El voluntario',
    2: 'Cuidador',
    3: 'Asistente social',
    4: 'Entrenador',
    5: 'Psiquiatra',
    6: 'Psicólogo',
    7: 'El paciente se ha recuperado'
}

const WEEKDAY_VN_FULL = {
    0: 'Chủ Nhật',
    1: 'Thứ Hai',
    2: 'Thứ Ba',
    3: 'Thứ Tư',
    4: 'Thứ Năm',
    5: 'Thứ Sáu',
    6: 'Thứ Bảy',
}

const WEEKDAY_EN_FULL = {
    0: 'Sunday',
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
}

const WEEKDAY_ESP_FULL = {
    0: 'domingo',
    1: 'lunes',
    2: 'martes',
    3: 'miércoles',
    4: 'jueves',
    5: 'viernes',
    6: 'sábado',
}

const EMOJI_VN = {
    search: 'Tìm kiếm',
    clear: 'Xóa', // Accessible label on "clear" button
    notfound: 'Không tìm thấy biểu tượng cảm xúc',
    skintext: 'Chọn tông màu da mặc định của bạn',
    categories: {
        search: 'Kết quả Tìm kiếm',
        recent: 'Thường được Sử dụng',
        smileys: 'Mặt cười & Cảm xúc',
        people: 'Con người & Cơ thể',
        nature: 'Động vật & Thiên nhiên',
        foods: 'Đồ ăn & Đồ uống',
        activity: 'Hoạt động',
        places: 'Du lịch & Địa điểm',
        objects: 'Đối tượng',
        symbols: 'Biểu tượng',
        flags: 'Cờ',
    },
    categorieslabel: 'Các danh mục biểu tượng cảm xúc', // Accessible title for the list of categories
    skintones: {
        1: 'Màu da mặc định',
        2: 'Tông màu da sáng',
        3: 'Tông màu Da sáng Trung bình',
        4: 'Tông màu Da Trung bình',
        5: 'Tông màu Da Trung bình-Tối',
        6: 'Tông màu da tối',
    },
}

const EMOJI_EN = {
    search: 'Search',
    clear: 'Clear', // Accessible label on "clear" button
    notfound: 'No Emoji Found',
    skintext: 'Choose your default skin tone',
    categories: {
        search: 'Search Results',
        recent: 'Frequently Used',
        smileys: 'Smileys & Emotion',
        people: 'People & Body',
        nature: 'Animals & Nature',
        foods: 'Food & Drink',
        activity: 'Activity',
        places: 'Travel & Places',
        objects: 'Objects',
        symbols: 'Symbols',
        flags: 'Flags',
        custom: 'Custom',
    },
    categorieslabel: 'Emoji categories', // Accessible title for the list of categories
    skintones: {
        1: 'Default Skin Tone',
        2: 'Light Skin Tone',
        3: 'Medium-Light Skin Tone',
        4: 'Medium Skin Tone',
        5: 'Medium-Dark Skin Tone',
        6: 'Dark Skin Tone',
    },
}

const EMOJI_ESP = {
    search: 'Buscar',
    clear: 'Clara',
    notfound: 'No se encontraron emojis',
    skintext: 'Elija su tono de piel predeterminado',
    categories: {
        search: 'Resultados de la búsqueda',
        recent: 'Usado con frecuencia',
        smileys: 'Emoticonos y emoción',
        people: 'Personas y cuerpo',
        nature: 'Animales y naturaleza',
        foods: 'Comida y bebida',
        activity: 'Actividad',
        places: 'Viajes y lugares',
        objects: 'Objetos',
        symbols: 'Símbolos',
        flags: 'Banderas',
        custom: 'Personalizado',
    },
    categorieslabel: 'Categorías de emoji',
    skintones: {
        1: 'Tono de piel predeterminado',
        2: 'Tono de piel claro',
        3: 'Tono de piel medio-claro',
        4: 'Tono de piel medio',
        5: 'Tono de piel medio oscuro',
        6: 'Tono de piel oscuro',
    },
}

const HOSPITAL_CLINIC_TYPE_VN = {
    0: "Phòng khám",
    1: "Bệnh viện",
    2: "Phòng khám tư"
}
const HOSPITAL_CLINIC_TYPE_EN = {
    0: "Clinic/CHC",
    1: "Hospital",
    2: "Personal Clinics"
}
const HOSPITAL_CLINIC_TYPE_ESP = {
    0: "Clínica",
    1: "Hospital",
    2: "Clínicas Personales"
}

const RELATIONSHIP_VN = [
    {key: 1, value: 'Bố/Mẹ'},
    {key: 2, value: 'Ông/Bà'},
    {key: 3, value: 'Con'},
    {key: 4, value: 'Anh/Chị/Em'},
    {key: 5, value: 'Bạn bè'},
    {key: 6, value: 'Chồng/Vợ'},
    {key: 7, value: 'Khác'},
]

const RELATIONSHIP_EN = [
    {key: 1, value: 'Mom/Dad'},
    {key: 2, value: 'Grandfather/Grandmother'},
    {key: 3, value: 'Child'},
    {key: 4, value: 'Brother/Sister'},
    {key: 5, value: 'Friends'},
    {key: 6, value: 'Husband/Wife'},
    {key: 7, value: 'Other'},
]


const RELATIONSHIP_ESP = [
    {key: 1, value: 'Mamá/Papá'},
    {key: 2, value: 'Abuelo/Abuela'},
    {key: 3, value: 'Niño'},
    {key: 4, value: 'Hermano/Hermana'},
    {key: 5, value: 'Amigos'},
    {key: 6, value: 'Esposo/Esposa'},
    {key: 7, value: 'Otro'},
]


export default LANGUAGE_CONFIG;
export {
  WEEKDAY_VN,
  WEEKDAY_EN,
  WEEKDAY_ESP,
  REGISTER_VN,
  REGISTER_EN,
  REGISTER_ESP,
  JOB_EN,
  JOB_ESP,
  JOB_VN,
  ACADEMIC_LEVEL_ESP,
  ACADEMIC_LEVEL_EN,
  ACADEMIC_LEVEL_VN,
  STATE_EVENT_VN,
  STATE_EVENT_EN,
  STATE_EVENT_ESP,
  FULL_WEEKDAY_VN,
  FULL_WEEKDAY_EN,
  FULL_WEEKDAY_ESP,
  ACCOUNT_TYPE_VN,
  ACCOUNT_TYPE_EN,
  ACCOUNT_TYPE_ESP,
  WEEKDAY_VN_FULL,
  WEEKDAY_EN_FULL,
  WEEKDAY_ESP_FULL,
  EMOJI_VN,
  EMOJI_EN,
  EMOJI_ESP,
  FORGOT_PASSWORD_VN,
  FORGOT_PASSWORD_EN,
  FORGOT_PASSWORD_ESP,
  HOSPITAL_CLINIC_TYPE_VN,
  HOSPITAL_CLINIC_TYPE_EN,
  HOSPITAL_CLINIC_TYPE_ESP,
  RELATIONSHIP_VN,
  RELATIONSHIP_EN,
  RELATIONSHIP_ESP
}
