import ACTION from "../../config/contants/action";
import {clearAuth, clearAuthentic} from "../../repositories/localStorage/clear";
import {getAuthenticFlag, getAuthProfile} from "../../repositories/localStorage/get";
import {setAuthProfile, setAuthenticFlag} from "../../repositories/localStorage/set";

const initialState = {
	auth: getAuthProfile() || {},
	authentic: getAuthenticFlag() || false
};

const authReducer = (state = initialState, action) => {
	switch (action.type) {
		case ACTION.LOGIN_SUCCESS: {
			const newAuth = action.payload.auth;
			const flagLogin = true
			setAuthProfile(newAuth)
			setAuthenticFlag(flagLogin)
			return {
				...state,
				auth: newAuth,
				authentic: flagLogin
			}
		}
		
		case ACTION.LOGOUT: {
			clearAuth();
			clearAuthentic();
			return {
				auth: {},
				authentic: false
			}
		}
		case ACTION.UPDATE_TIME_PHQ9: {
			const newAuth = {
				...state.auth,
				time_reply_phq9 : action.payload
			}
			setAuthProfile(newAuth)
			return state;
		}
		case ACTION.UPDATE_INFO: {
			const newAuth = action.payload;
			const flagLogin = true
			setAuthProfile(newAuth)
			setAuthenticFlag(flagLogin)
			return {
				auth: newAuth,
				authentic: flagLogin
			}
		}
		
		default:
			return state;
	}
}

export default authReducer;