import React from 'react';
import { BrowserRouter as Router, Switch, Redirect, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import Policy from "../containers/policy";
import TermsOfUse from "../containers/terms-of-use";
import QuestionsAnswers from "../containers/questions-answers";
import routes from './routers';
import Public from './Public';
import Private from './Private';

const history = createBrowserHistory();

function RouterDom() {
	return (
  <Router history={history}>
    <Switch>
      <Redirect exact from='/' to='/home' />
      {routes.map((route) => {
					if (route.auth) {
						return <Private exact key={route.key} {...route} />;
					}
					return <Public exact key={route.key} {...route} />;
				})}
      <Route
        component={Policy}
        exact
        path='/policy'
      />
      <Route
        component={QuestionsAnswers}
        exact
        path='/questions-answers'
      />
      <Route
        component={TermsOfUse}
        exact
        path='/terms-of-use'
      />
      <Redirect to='/home' />
    </Switch>
  </Router>
	);
}

export default RouterDom;
