import React from 'react';
import './App.css';
import "./config/i18n";
import RouterDom from "./router";
import {SocketProvider} from "./contexts/SocketContext";

function App() {
  return (
    <SocketProvider>
      <RouterDom />
    </SocketProvider>
  );
}

export default App;
