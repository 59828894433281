import CryptoAES from 'crypto-js/aes'
import SECRET from "../../config/contants/secret";

const setRememberPassword = (data) => {
	const passwordEncrypt = CryptoAES.encrypt(data.password, SECRET.SECRET_KEY);
	const newData = {
		...data,
		password: passwordEncrypt.toString()
	}
	localStorage.setItem("rememberPassword", JSON.stringify(newData));
}

const setAuthProfile = (data) => {
	localStorage.setItem("auth", JSON.stringify(data));
}

const setAuthenticFlag = (flag) => {
	localStorage.setItem("authentic", flag);
}

const setLanguageForBrowser = (lang) => {
	localStorage.setItem("lang", lang);
}

const setDateCallApi = (date) => {
	localStorage.setItem("dateCall", date);
}

export {
	setRememberPassword,
	setAuthProfile,
	setAuthenticFlag,
	setLanguageForBrowser,
	setDateCallApi
}