export default [
    {
        key: 1,
        path: '/login',
        component: 'login',
        auth: false
    },
    {
        key: 2,
        path: '/home',
        component: 'about-vmood',
        auth: true,
    },
    {
        key: 3,
        path: '/phq9',
        component: 'phq9',
        auth: true,
    },
    {
        key: 4,
        path: '/introduce',
        component: 'introduce',
        auth: true
    },
    {
        key: 5,
        path: '/practice-positive-thinking',
        component: 'practice-positive-thinking',
        auth: true
    },
    {
        key: 6,
        path: '/problem-solving',
        component: 'problem-solving',
        auth: true
    },
    {
        key: 7,
        path: '/total-action',
        component: 'total-action',
        auth: true
    },
    {
        key: 8,
        path: '/problem-solving/detail/:id',
        component: 'video-detail',
        auth: true
    },
    {
        key: 9,
        path: '/reactivate-your-life',
        component: 'activities-re-life',
        auth: true
    },
    {
      key: 10,
      path: '/relapse-prevention',
      component: 'activities-re-life',
      auth: true
    },
    {
        key: 11,
        path: '/404',
        component: 'Notfound',
        auth: true
    },
    {
        key: 12,
        path: '/expert',
        component: 'expert',
        auth: true
    },
    {
        key: 13,
        path: '/expert/:id',
        component: 'expert-information',
        auth: true
    },
    {
        key: 14,
        path: '/register',
        component: 'register',
        auth: false
    },
    {
        key: 15,
        path: '/converse',
        component: 'converse',
        auth: true
    },
    {
        key: 16,
        path: '/information',
        component: 'information',
        auth: true
    },
    {
        key :17,
        path: '/hospital-clinic',
        component: 'hospital-clinic',
        auth: true
    },
    {
        key :18,
        path: '/forgot-password',
        component: 'forgot-password',
        auth: false
    },
    {
        key :18,
        path: '/hospital-clinic/:id',
        component: 'hospital-clinic-detail',
        auth: true
    },
    {
        key :19,
        path: '/reference-readings',
        component: 'reference-readings',
        auth: true
    },
    {
        key :20,
        path: '/products',
        component: 'products',
        auth: true
    },
    {
        key :21,
        path: '/practice-positive-thinking-required',
        component: 'practice-positive-thinking-required',
        auth: true
    },
    {
        key :22,
        path: '/assessment-required',
        component: 'assessment-required',
        auth: true
    },
    {
        key: 23,
        path: '/about-vmood',
        component: 'about-vmood',
        auth: true,
    },
    {
        key: 24,
        path: '/depression-overview',
        component: 'depression-overview',
        auth: true,
    },
    {
        key: 25,
        path: '/relapse-prevention/introduction',
        component: 'introduction',
        auth: true,
    },
    {
        key: 26,
        path: '/reactivate-your-life/introduction',
        component: 'introduction',
        auth: true,
    },
    {
        key: 27,
        path: '/practice-positive-thinking/introduction',
        component: 'introduction',
        auth: true,
    },
    {
        key: 28,
        path: '/problem-solving/introduction',
        component: 'introduction',
        auth: true,
    },
];
