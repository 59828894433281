import React, {useContext, useEffect, useState} from 'react';
import {useTranslation} from "react-i18next";
import {Link, useHistory} from 'react-router-dom'
import {useDispatch, useSelector} from "react-redux";
import moment from "moment";
import PropTypes from 'prop-types';
import {toast} from 'react-toastify';
import Dropdown from 'react-bootstrap/Dropdown'
import {Logout, UpdateInfo} from "../../features/Login/action";
import './header.css';
import Language from "../../features/Language";
import Profile from "../Profile";
import logo from "../../assets/images/logo-header.svg";
import notifyAPI from "../../api/notify";
import GetLanguageConfigCode from "../../repositories/state/get";
import {getTimeZoneName} from "../../repositories/localStorage/get";
import 'react-toastify/dist/ReactToastify.css';
import ChangeInfoApi from "../../api/changeInfoApi";
import TimeZoneApi from "../../api/timeZone";
import ReturnHour from "../../features/TotalAction/return-hours";
import AssessmentAPI from "../../api/assessment";
import CheckCallQuestion from "../../repositories/api/checkCallQuestion";
import {SocketContext} from "../../contexts/SocketContext";
import problemSolving from "../../api/problemSolving";
import modalSetting from "../../api/modalSetting";

function Header(props) {
  const {isPhq9, requiredProfile} = props;
  const {t} = useTranslation();
  const history = useHistory();
  const flagPhq9 = CheckCallQuestion();
  const [listNotify, setListNotify] = useState();
  const [countNotify, setCountNotify] = useState(0);
  const dispatch = useDispatch();
  const dateLocal = moment.utc(new Date()).toDate();
  const language = useSelector(state => state.language.lang);
  const auth = useSelector(state => state.auth.auth);
  const authStorage = JSON.parse(localStorage.getItem('auth'));
  const isAuthentic = useSelector(state => state.auth.authentic);
  const {socket} = useContext(SocketContext);
  const handleLogout = () => {
    socket.disconnect();
    const action = Logout(true);
    dispatch(action);
    window.location.href = "/login";
  };

  useEffect( () => {
    (async () => {
      try {
        const response = await problemSolving.getMusicBackground(auth.access_token);
        const {status, data} = response;
        if (status === 200) {
          localStorage.setItem('soundUrl', data.data)
        }
      } catch (e) {
        console.log("failed to fetch data", e);
      }
    })();
  }, [])

  useEffect( () => {
    (async () => {
      try {
        const response = await modalSetting.getListModal(auth.access_token);
        const {status, data} = response;
        if (status === 200) {
          localStorage.setItem('modalSetting', JSON.stringify(data.data))
        }
      } catch (e) {
        console.log("failed to fetch data", e);
      }
    })();
  }, [])

  useEffect( () => {
    if (auth && isAuthentic && ((window.location.pathname.indexOf('depression-overview') === -1 && window.location.pathname.indexOf('about-vmood') === -1) || authStorage.type_member === 2)) {
      const callProfile = async () => {
        const response = await ChangeInfoApi.callProfile(auth.access_token);
        const {status} = response
        if (status === 200 && response.data.data) {
          if (JSON.stringify(auth) !== JSON.stringify(response.data.data)) {
            const action = UpdateInfo(response.data.data)
            dispatch(action);
          }
        } else if (status === 412) {
          handleLogout();
          toast.error(t('notify.access_token_false'),
        {
        toastId: "access_token_false"
      });
        }
      }
      callProfile();
    }
  }, [])
  function logout() {
    const online = window.navigator.onLine;
    if (online) {
      handleLogout()
    } else {
      window.location.reload(true)
    }
  };

  const callNotify = async (params) => {
    let response;
    if (params) {
      response = await notifyAPI.getAllNotify(auth.access_token, params);
    } else {
      response = await notifyAPI.getAllNotify(auth.access_token, {language_id: GetLanguageConfigCode(language)});
    }
    if (response.status === 200 && response.data.data) {
      response = await notifyAPI.getAllNotify(auth.access_token, {language_id: GetLanguageConfigCode(language)});
      if (response.status === 200 && response.data.data) {
        const {data} = response.data;
        if (Array.isArray(data)) {
          const countData = data.reduce((sum, index) => {
            if (index.status === 0) {
              sum += 1;
            }
            return sum;
          }, 0);
          setListNotify(data);
          setCountNotify(countData);
        } else {
          setCountNotify(0)
        }
      } else if (response.status === 412) {
        setTimeout(1000);
        toast.error(t('notify.access_token_false'),
        {
        toastId: "access_token_false"
      });
        handleLogout()
      }
    } else if (response.status === 412) {
      setTimeout(1000);
      toast.error(t('notify.access_token_false'),
        {
        toastId: "access_token_false"
      });
      handleLogout()
    }

  };


  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (auth.access_token && isAuthentic) {
      callNotify();
      const notify = setInterval(() => {
        callNotify();
      }, 60000);
      return () => {
        clearInterval(notify);
      }
    }
  }, [language]);

  const clickNotification = (id) => {
    callNotify({
      language_id: GetLanguageConfigCode(language),
      status: 1,
      id
    });
  };

  useEffect(() => {
    if (auth && isAuthentic) {
      const pushTimeZone = async () => {
        const response = await TimeZoneApi.updateTimeZone({time_zone:getTimeZoneName()}, auth.access_token);
        const {status} = response
        if (status === 412) {
          handleLogout();
          setTimeout(() => {
            toast.error(t('notify.access_token_false'),
                {
                  toastId: "access_token_false"
                });
          }, 1000)
        }
        if (status === 422 && ((window.location.pathname.indexOf('depression-overview') === -1) || !authStorage.type_member)) {
          handleLogout();
          setTimeout(() => {
            toast.error(t('notify.access_token_false'),
                {
                  toastId: "access_token_false"
                });
          }, 1000)
        }
      }
      pushTimeZone();
    }
  }, [])
  useEffect(() => {
    const getListQuestionData = async () => {
      const params = {
        language_id:  GetLanguageConfigCode(language),
        time: ReturnHour(dateLocal)
      }
      const response = await AssessmentAPI.getListQuestion(auth.access_token, params);
      if (response.status === 200 && response.data.data) {
        if (response.data.data.length > 0 && window.location.pathname !== '/assessment-required') {
          window.location.href = "/assessment-required";
        }
      }
    }
    if (isAuthentic && !flagPhq9) {
      getListQuestionData();
    }
  }, [language, auth, isAuthentic])
  return (
    <header>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-md-4 col-12'>
            <div className={`header-left ${requiredProfile && "required-header"}`}>
              <div className='logo'>
                <Link to='/about-vmood'>
                  <img src={logo} alt='logo' />
                </Link>
                <h2 className='text-left slogan'>
                  Vmood<br />
                  <span className='wrap-slogan'>
                    <span className='slogan-1'>{t("language.slogan_1")}&nbsp;</span>
                    <span className='slogan-2'>
                      <span>{t("language.slogan_2")}</span>
                    </span>
                  </span>
                </h2>
              </div>
            </div>
          </div>
          <div className='col-md-8 col-12'>
            <div className='header-right d-flex'>
              <Language />
              {(isAuthentic && !isPhq9) &&
                <div className={`centered ${requiredProfile && "required-profile"}`}>
                  <Link to='/converse'><i className='fa fa-comment' aria-hidden='true' /></Link>
                  <Dropdown className='wrap-notify'>
                    <Dropdown.Toggle id='dropdown-basic' className='button-notify' variant={false}>
                      <i className='fa fa-bell icon-grey m-0'>
                        {countNotify !== 0 &&
                          <p className='number-notify'>{countNotify}</p>}
                      </i>
                    </Dropdown.Toggle>
                    <Dropdown.Menu className='dropdown-menu-arrow'>
                      <div className='content-list-notify'>
                        <span className='title'>{t('notify.title')}</span>
                        {listNotify && listNotify.map((value, index) => (
                          <Dropdown.Item
                            key={index} className={`child-notify ${value.status === 0 && 'new-notify'}`}
                            onClick={() => clickNotification(value.id)}
                          >
                            <span><i className='far fa-bell icon' /></span>
                            <div className='text-time'>
                              <div className='text'>{value.message}</div>
                              <div className='notify-time'>{value.date}</div>
                            </div>
                          </Dropdown.Item>
                      ))}
                      </div>
                    </Dropdown.Menu>
                  </Dropdown>
                  <Profile
                      logoutLogic={logout}
                      points={auth.points}
                      username={auth.full_name}
                      avatar={auth.avatar_url}
                      requestExpert={auth?.request_expert}
                  />
                </div>}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
}

Header.propTypes = {
  isPhq9: PropTypes.bool,
};

Header.defaultProps = {
  isPhq9: false
};

export default Header;
