import React, {useEffect, useState} from 'react';
import {Navbar, Nav, NavDropdown} from "react-bootstrap";
import './sidebar.css';
import {NavLink} from "react-router-dom";
import {useTranslation} from "react-i18next";
import TIMES from "../../config/contants/time";

function Sidebar(props) {
    const {t} = useTranslation();
    const {score, required} = props;
    const [activeClass, setActiveClass] = useState('');
    const [activeHomeClass, setActiveHomeClass] = useState('');
    const [activeRequired, setActiveRequired] = useState('');
    const [activeRainbow, setActiveRainbow] = useState(false);
    const pathName = window.location.pathname;
    const checkActive = () => {
        if (pathName.indexOf("expert") > 0 || pathName.indexOf("converse") > 0) {
            setActiveClass("active-expert");
        }
        if (pathName.indexOf("home") > 0) {
            setActiveHomeClass("active-home");
        }
        if (pathName.indexOf("required") > 0) {
            setActiveRequired("required-component");
        }
    }
    useEffect(() => {
      const practicePositiveUpdate = localStorage.getItem('practice_positive_update');
      const now = new Date(new Date().toLocaleString(TIMES.LOCATION, {timeZone: TIMES.TIME_ZONE}));
      const dateNow = `${now.getFullYear()}/${(now.getMonth() + 1)}/${now.getDate()}`;
      if (practicePositiveUpdate !== dateNow) {
        setActiveRainbow(true);
      } else {
        setActiveRainbow(false);
      }
    });
    useEffect(() => {
        checkActive();
    }, []);
    let reconstructionLifeActive = 'menu-item';
  // let reconstructionLifeActive = 'menu-item inActive';
    let preventionOfRecurrenceActive = 'menu-item';

    if (parseInt(score, 10) > 9) {
        preventionOfRecurrenceActive = 'menu-item inActive';
        reconstructionLifeActive = 'menu-item';
    }
    return (
      <Navbar bg='success' expand='lg' className={`menu-sidebar ${activeRequired} ${required ? 'sidebar-required' : ''}`}>
        <Navbar.Toggle aria-controls='basic-navbar-nav' />
        <Navbar.Collapse id='basic-navbar-nav'>
          <Nav className='mr-auto'>
            <NavLink to='/about-vmood' activeClassName='' className={`menu-item ${activeHomeClass} ${pathName.indexOf('about-vmood') !== -1 ? 'menu-selected' : ''}`}>
              <div className='numberCircle'>1</div>
              {t('sidebar.about_vmood')}
            </NavLink>
            <NavLink to='/depression-overview' activeClassName='' className={`menu-item ${pathName.indexOf('depression-overview') !== -1 ? 'menu-selected' : ''}`}>
              <div className='numberCircle'>2</div>
              {t('sidebar.depression_overview')}
            </NavLink>
            <NavLink to='/problem-solving' onClick={() => window.location.href = '/problem-solving'} activeClassName='menu-selected' className={`menu-item ${pathName.indexOf('problem-solving') !== -1 ? 'menu-selected' : ''}`}>
              <div className='numberCircle'>3</div>
              {t('sidebar.problem_solving')}
            </NavLink>
            <NavLink to='/reactivate-your-life' activeClassName='menu-selected' className={`${(pathName.indexOf('total-action') > 0 && parseInt(score, 10) > 9) && 'menu-selected'}  ${reconstructionLifeActive}`}>
              <div className='numberCircle'>4</div>
              {t('sidebar.reconstruction_life')}
            </NavLink>
            <NavLink to='/practice-positive-thinking' activeClassName='menu-selected' className={activeRainbow ? 'rainbow' : 'menu-item'}>
              <div className='numberCircle'>5</div>
              {t('sidebar.practice_positive_thinking')}
            </NavLink>
            <NavLink
              to='/relapse-prevention' activeClassName='menu-selected' className={`menu-item ${pathName.indexOf('relapse-prevention') !== -1 ? 'menu-selected' : ''}`}
                     // className={`${(pathName.indexOf('total-action') > 0 && parseInt(score, 10) <= 9) && 'menu-selected'} ${preventionOfRecurrenceActive}`}
            >
              <div className='numberCircle'>6</div>
              {t('sidebar.prevention_of_recurrence')}
            </NavLink>
            <div className={`menu-item dropdown-expert ${(pathName.indexOf('products') > 0 || pathName.indexOf('reference-readings') > 0) && 'menu-selected'}`}>
              <div className='numberCircle'>7</div>
              <NavDropdown title={t('sidebar.product_and_references')} id='collasible-nav-dropdown' className={`menu-item ${(pathName.indexOf('products') > 0 || pathName.indexOf('reference-readings') > 0) && 'active-pro-ref'}`}>
                <NavLink to='/products' className='menu-item' activeClassName='menu-selected-dropItem'>{t('sidebar.product')}</NavLink>
                <NavLink to='/reference-readings' className='menu-item' activeClassName='menu-selected-dropItem'>{t('sidebar.references')}</NavLink>
              </NavDropdown>
            </div>
            <div className={`menu-item dropdown-expert ${(pathName.indexOf('expert') > 0 || pathName.indexOf('converse') > 0) && 'menu-selected'}`}>
              <div className='numberCircle'>8</div>
              <NavDropdown title={t('sidebar.talk_to_expert')} id='collasible-nav-dropdown' className={`menu-item ${activeClass}`}>
                <NavLink to='/expert' className='menu-item' activeClassName='menu-selected-dropItem'>{t('sidebar.list_expert')}</NavLink>
                <NavLink to='/converse' className='menu-item' activeClassName='menu-selected-dropItem'>{t('sidebar.converse')}</NavLink>
              </NavDropdown>
            </div>
            <NavLink to='/hospital-clinic' className='menu-item' activeClassName='menu-selected'>
              <div className='numberCircle'>9</div>
              {t('sidebar.hospital_clinic')}
            </NavLink>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    );
}

export default Sidebar;
