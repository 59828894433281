import axiosClient from "./axiosClient";

const AssessmentAPI = {
  getListQuestion: (accessToken, params) => {
    const url = `/users/other-assessment/new`;
    return axiosClient.get(url, {
      headers: {
        "Access-Token": accessToken
      },
      params,
      validateStatus(status) {
        return status;
      }
    });
  },
  saveDataQuestion: (accessToken, params) => {
    const url = `/users/other-assessment/create`;
    return axiosClient.post(url, params, {
      headers: {
        "Access-Token": accessToken
      },
      validateStatus(status) {
        return status;
      }
    });
  }
};
export default AssessmentAPI;
