import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import './profile.css';
import {Link} from "react-router-dom"
import {useTranslation} from "react-i18next";
import {Button, Spinner} from "react-bootstrap";
import avatarDefault from '../../assets/images/default_avatar_user.png';
import {Response} from "../../config/contants/common";
import CheckScorePhq9 from "../../repositories/api/checkScorePhq9";
import Phq9Api from "../../api/phq9";
import {useSelector} from "react-redux";

function Profile(props) {
    const {t} = useTranslation();
    const {username, avatar, logoutLogic, points, requestExpert} = props;
    const [disableLogout, setDisableLogout] = useState(false)
    const [expertAccount, setExpertAccount] = useState(false);
    const auth = useSelector(state => state.auth.auth);
    const scorePhq9 = CheckScorePhq9();

  function handleLogout() {
      setDisableLogout(true)
      if (logoutLogic) {
        logoutLogic();
      }
    }

    useEffect(() => {
        (async () => {
            try {
                const response = await Phq9Api.averageScore(auth.access_token);
                if (response.status === 200) {
                    setExpertAccount(response.data.data)
                    localStorage.setItem('expertAccount', response.data.data)
                }
            } catch (e) {
                console.log("failed to fetch data", e);
            }
        })();
    },[])

    return (
        <div className="profile">
            <div className="profile dropdown">
                <div className="dropdown-toggle centered-vertical" data-toggle="dropdown">
                  <div className='parent-img' style={{backgroundImage: `url(${avatar ?? avatarDefault})`}} />
                  <div className="user-name" id="user-name">
                    <p>{username}</p>
                    <p>{`${points || 0} ${t('language.point')}`}</p>
                  </div>
                </div>
                <div className="dropdown-menu">
                  <Button disabled={disableLogout} className='dropdown-item'>
                    <Link className='go-to-info' to='/information'>{t('information.info_text')}</Link>
                  </Button>
                  <Button disabled={disableLogout} className='dropdown-item'>
                    <Link className='go-to-info' to='/information?action=change-password'>{t('information.edit_password')}</Link>
                  </Button>
                  <Button disabled={disableLogout} className='dropdown-item'>
                    <Link className='go-to-info' to='/information?action=purchase-coin'>{t('information.add_point')}</Link>
                  </Button>
                  {requestExpert === Response.DEFAULT && (
                     <Button disabled={disableLogout || !expertAccount} className='dropdown-item'>
                        <Link className='go-to-info' to='/information?action=expert-account'>{t('information.title_expert_account')}</Link>
                     </Button>
                  )}
                  <Button disabled={disableLogout} className='dropdown-item' onClick={handleLogout}>
                    {disableLogout && <Spinner size='sm' animation='border' variant='success' />}
                    {t("language.logout")}
                  </Button>
                </div>
            </div>
        </div>
    );
}

Profile.propTypes = {
  username: PropTypes.string,
  logoutLogic: PropTypes.func,
  avatar: PropTypes.string
};

Profile.defaultProps = {
  username: '',
  avatar: avatarDefault,
  logoutLogic: null
}

export default Profile;
