import {useEffect, useState} from 'react';
import {useSelector} from "react-redux";
import GetLanguageConfigCode from "../state/get";
import language from "../../config/contants/language";
import RealisticThinkingApi from "../../api/realisticThinking";

const CheckScorePhq9 = () => {
    const auth = useSelector(state => state.auth.auth);
    const scorePhq9Data = localStorage.getItem('scorePhq9');
    const [score, setScore] = useState('');
    useEffect(() => {
        const fetchRealisticThinkingList = async () => {
            try {
                const params = {
                    language_id: GetLanguageConfigCode(language),
                }
                const response = await RealisticThinkingApi.getAll(params, auth.access_token);
                const {score_phq9: scorePhq9} = response.data.data.result;
                setScore(scorePhq9);
                localStorage.setItem('scorePhq9', scorePhq9);
            }
            catch (e) {
                console.log("failed to fetch data ",e);
            }

        }
        fetchRealisticThinkingList();
    }, [scorePhq9Data]);
    return scorePhq9Data || score;


}

export default CheckScorePhq9;
