import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import LANGUAGE_CONFIG from "../../config/contants/language";
import changeLanguage from "./action";
import earthIcon from "../../assets/images/header-icon.png";
import './language.css';
import iconEngland from "../../assets/images/icons/united-kingdom-icon.svg"
import iconSpain from "../../assets/images/icons/spain-icon.svg"
import iconVietnam from "../../assets/images/icons/vietnam-icon.svg"

export default function FeatureLanguage() {
  const dispatch = useDispatch();
  const languageLocal = useSelector((state) => state.language.lang);
  const { t, i18n } = useTranslation();
  const changeLanguageView = (event) => {
    const newLang = event.target.value;
    const action = changeLanguage({
      language: newLang,
    });
    dispatch(action);
    i18n.changeLanguage(newLang);
  };
  return (
    <form className='form-select-language'>
      <div className='earth-icon'>
        <img src={earthIcon} alt='earth-icon' />
      </div>
      <div>
        <FormControl className='language-select'>
          <Select
            value={languageLocal}
            onChange={changeLanguageView}
            onBlur={() => {}}
            className='centered-vertical'
          >
            <MenuItem value={LANGUAGE_CONFIG.EN}>{t("language.en")}<img alt=' ' className='icon-flag' src={iconEngland} /></MenuItem>
            <MenuItem value={LANGUAGE_CONFIG.VN}>{t("language.vn")}<img alt=' ' className='icon-flag' src={iconVietnam} /></MenuItem>
            <MenuItem value={LANGUAGE_CONFIG.ESP}>{t("language.esp")}<img alt=' ' className='icon-flag' src={iconSpain} /></MenuItem>
          </Select>
        </FormControl>
      </div>
    </form>
  );
}
