import React from 'react';
import {useSelector} from "react-redux";
import Vietnamese from "../features/TermsOfUse/vietnamese";
import Header from "../components/Header";
import Footer from "../components/Footer/footer";
import Sidebar from "../components/Sidebar";
import CheckScorePhq9 from "../repositories/api/checkScorePhq9";
import LANGUAGE_CONFIG from "../config/contants/language";
import English from "../features/TermsOfUse/english";

function TermsOfUse() {
  const lang = useSelector(state => state.language.lang);
  const auth = useSelector(state => state.auth);
  let scorePhq9 = 0;
  if (auth.authentic) {
    scorePhq9 = CheckScorePhq9();
  }
  const renderContent = () => {
    switch (lang) {
      case LANGUAGE_CONFIG.VN:
        return <Vietnamese />;
      case LANGUAGE_CONFIG.EN:
        return <English />
      case LANGUAGE_CONFIG.ESP:
        return <English />
      default:
        return <English />
    }
  }
  return (
    <article id='wrapper'>
      <Header />
      {auth.authentic && <Sidebar score={scorePhq9} />}
      <div id='wrap'>
        {renderContent()}
      </div>
      <Footer />
    </article>
  )
}

export default TermsOfUse;