import axiosClient from "./axiosClient";

const modalSettingApi = {
  getListModal: (accessToken) => {
    const url = "/modal_settings/get_list_modal";
    return axiosClient.get(url, {
      headers: {
        "Access-Token": accessToken,
      },
      validateStatus (status) {
        return status;
      }
    });
  },
};

export default modalSettingApi;
