import LANGUAGE_CONFIG, {
	WEEKDAY_VN,
	WEEKDAY_ESP,
	WEEKDAY_EN,
	FULL_WEEKDAY_VN,
	FULL_WEEKDAY_ESP,
	FULL_WEEKDAY_EN,
	REGISTER_VN,
	REGISTER_EN,
	REGISTER_ESP,
	ACADEMIC_LEVEL_VN,
	ACADEMIC_LEVEL_EN,
	ACADEMIC_LEVEL_ESP,
	JOB_VN,
	JOB_ESP,
	JOB_EN,
	EMOJI_VN,
	EMOJI_EN,
	EMOJI_ESP,
	FORGOT_PASSWORD_ESP,
	FORGOT_PASSWORD_EN,
	FORGOT_PASSWORD_VN,
	HOSPITAL_CLINIC_TYPE_VN,
	HOSPITAL_CLINIC_TYPE_EN,
	HOSPITAL_CLINIC_TYPE_ESP,
    RELATIONSHIP_VN,
    RELATIONSHIP_EN,
    RELATIONSHIP_ESP,
    ACCOUNT_TYPE_EN,
    ACCOUNT_TYPE_ESP,
    ACCOUNT_TYPE_VN
} from "../../config/contants/language";

const GetLanguageConfigCode = (language) => {
    switch (language) {
        case LANGUAGE_CONFIG.VN:
            return LANGUAGE_CONFIG.LANGUAGE_CODE_CONFIG.VN
        case LANGUAGE_CONFIG.EN:
            return LANGUAGE_CONFIG.LANGUAGE_CODE_CONFIG.EN
        case LANGUAGE_CONFIG.ESP:
            return LANGUAGE_CONFIG.LANGUAGE_CODE_CONFIG.ESP
        default:
            return LANGUAGE_CONFIG.LANGUAGE_CODE_CONFIG.EN
    }
}

const GetLanguageWeekDay = (language) => {
    switch (language) {
        case LANGUAGE_CONFIG.VN:
            return WEEKDAY_VN
        case LANGUAGE_CONFIG.EN:
            return WEEKDAY_EN
        case LANGUAGE_CONFIG.ESP:
            return WEEKDAY_ESP
        default:
            return WEEKDAY_EN
    }
};

const GetFullLanguageWeekDay = (language) => {
    switch (language) {
        case LANGUAGE_CONFIG.VN:
            return FULL_WEEKDAY_VN
        case LANGUAGE_CONFIG.EN:
            return FULL_WEEKDAY_EN
        case LANGUAGE_CONFIG.ESP:
            return FULL_WEEKDAY_ESP
        default:
            return FULL_WEEKDAY_EN
    }
}

const GetLanguageSteps = (language) => {
    switch (language) {
        case LANGUAGE_CONFIG.VN:
            return REGISTER_VN
        case LANGUAGE_CONFIG.EN:
            return REGISTER_EN
        case LANGUAGE_CONFIG.ESP:
            return REGISTER_ESP
        default:
            return REGISTER_EN
    }
}

const GetForgotPasswordSteps = (language) => {
	switch (language) {
		case LANGUAGE_CONFIG.VN:
			return FORGOT_PASSWORD_VN
		case LANGUAGE_CONFIG.EN:
			return FORGOT_PASSWORD_EN
		case LANGUAGE_CONFIG.ESP:
			return FORGOT_PASSWORD_ESP
		default:
			return FORGOT_PASSWORD_EN
	}
}

const GetLanguageLocal = (language) => {
    switch (language) {
        case LANGUAGE_CONFIG.VN:
            return LANGUAGE_CONFIG.LANGUAGE_CODE_LOCAL.VN
        case LANGUAGE_CONFIG.EN:
            return LANGUAGE_CONFIG.LANGUAGE_CODE_LOCAL.EN
        case LANGUAGE_CONFIG.ESP:
            return LANGUAGE_CONFIG.LANGUAGE_CODE_LOCAL.ESP
        default:
            return LANGUAGE_CONFIG.LANGUAGE_CODE_LOCAL.EN
    }
}

const GetLanguageAcademicLevel = (language) => {
    switch (language) {
        case LANGUAGE_CONFIG.VN:
            return ACADEMIC_LEVEL_VN
        case LANGUAGE_CONFIG.EN:
            return ACADEMIC_LEVEL_EN
        case LANGUAGE_CONFIG.ESP:
            return ACADEMIC_LEVEL_ESP
        default:
            return ACADEMIC_LEVEL_EN
    }
}

const GetLanguageJob = (language) => {
    switch (language) {
        case LANGUAGE_CONFIG.VN:
            return JOB_VN
        case LANGUAGE_CONFIG.EN:
            return JOB_EN
        case LANGUAGE_CONFIG.ESP:
            return JOB_ESP
        default:
            return JOB_EN
    }
}

const GetLanguageEmoji = (language) => {
    switch (language) {
        case LANGUAGE_CONFIG.VN:
            return EMOJI_VN
        case LANGUAGE_CONFIG.EN:
            return EMOJI_EN
        case LANGUAGE_CONFIG.ESP:
            return EMOJI_ESP
        default:
            return EMOJI_EN
    }
}
const GetHospitalType = (language) => {
    switch (language) {
        case LANGUAGE_CONFIG.VN:
            return HOSPITAL_CLINIC_TYPE_VN
        case LANGUAGE_CONFIG.EN:
            return HOSPITAL_CLINIC_TYPE_EN
        case LANGUAGE_CONFIG.ESP:
            return HOSPITAL_CLINIC_TYPE_ESP
        default:
            return HOSPITAL_CLINIC_TYPE_EN
    }
}
const GetRelationship = (language) => {
  switch (language) {
    case LANGUAGE_CONFIG.VN:
      return RELATIONSHIP_VN
    case LANGUAGE_CONFIG.EN:
      return RELATIONSHIP_EN
    case LANGUAGE_CONFIG.ESP:
      return RELATIONSHIP_ESP
    default:
      return RELATIONSHIP_EN
  }
}
const getExpertType = (language) => {
    switch (language) {
        case LANGUAGE_CONFIG.VN:
            return ACCOUNT_TYPE_VN
        case LANGUAGE_CONFIG.EN:
            return ACCOUNT_TYPE_EN
        case LANGUAGE_CONFIG.ESP:
            return ACCOUNT_TYPE_ESP
        default:
            return ACCOUNT_TYPE_EN
    }
}
const getTextByLang = (lang, textVn, textEn, textEsp, index) => {
    let text = ''
    switch (lang) {
        case LANGUAGE_CONFIG.VN:
            text = textVn[index]
            break;
        case LANGUAGE_CONFIG.EN:
            text = textEn[index]
            break;
        case LANGUAGE_CONFIG.ESP:
            text = textEsp[index]
            break;
        default:
            text = textEn[index]
            break;
    }
    return text
}
export default GetLanguageConfigCode
export {
	GetLanguageLocal,
	GetLanguageWeekDay,
    GetFullLanguageWeekDay,
	GetLanguageSteps,
	GetLanguageAcademicLevel,
	GetLanguageJob,
	GetLanguageEmoji,
	GetForgotPasswordSteps,
	GetHospitalType,
    GetRelationship,
    getTextByLang,
    getExpertType
}
