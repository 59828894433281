import {useSelector} from "react-redux";

const CheckCallQuestion = () => {
	const auth = useSelector(state => state.auth.auth);
	const isAuthentic = useSelector(state => state.auth.authentic);
	let flag = false;
	if (auth && isAuthentic && auth.time_reply_phq9) {
		const dateNow = new Date()
		const dateRepLyPhq9 = new Date(auth.time_reply_phq9);
		const diffTime = (dateNow.getTime() - dateRepLyPhq9.getTime())/(24*3600*1000);
		const diffDays = parseInt(diffTime, 0);
		flag = (diffDays === 0 || diffDays > 0) ?? true;
	} else if (auth && isAuthentic && !auth.time_reply_phq9) {
		return true
	} else {
		flag = false
	}
	return flag
}

function isValidDate(dateString) {
	const regEx = /^\d{4}-\d{1,2}-\d{1,2}$/;
	return dateString.match(regEx) != null;
}

const checkSetDateReply = () => {
	const dateOld = localStorage.getItem("dateCall");
	if (dateOld && isValidDate(dateOld)) {
		const dateNow = new Date()
		const dateRepLyPhq9 = new Date(dateOld);
		const diffTime = (dateNow.getTime() - dateRepLyPhq9.getTime())/(24*3600*1000);
		const diffDays = parseInt(diffTime, 0);
		if(diffDays > 0) {
			return true;
		}
		return false
	}
	return true;
}

export default CheckCallQuestion;
export {checkSetDateReply};
