import axiosClient from "./axiosClient";
import axiosClientWithToken from "./axiosClientWithToken";

const ProblemSolvingApi = {
    getAll: (params, accessToken) => {
        const url = "/videos/show";
        return axiosClient.get(url, {
            headers: {
                "Access-Token": accessToken
            },
            params,
            validateStatus(status) {
                return status;
            }
        });
    },
    getList: (params, accessToken) => {
        const url = "/difficult_problems/get_list?language=" + params;
        return axiosClient.get(url, {
            headers: {
                "Access-Token": accessToken
            },
            validateStatus(status) {
                return status;
            }
        });
    },
    getListQuestion: (params, accessToken) => {
        const url = "/difficult_problems/get_list_question";
        return axiosClient.get(url, {
            headers: {
                "Access-Token": accessToken
            },
            params,
            validateStatus(status) {
                return status;
            }
        });
    },
    getAllProblemType: (params, accessToken) => {
        const url = "/problems/index";
        return axiosClient.get(url, {
            headers: {
                "Access-Token": accessToken
            },

            params,
            validateStatus(status) {
                return status;
            }
        });
    },
    getVideoById: (params, accessToken) => {
        const url = '/videos/';
        return axiosClient.get(url, {
            headers: {
                "Access-Token": accessToken
            },

            params,
            validateStatus(status) {
                return status;
            }
        });
    },
    getMusicBackground: (accessToken) => {
        const url = "/phq9/get_music_background";
        return axiosClient.get(url, {
            headers: {
                "Access-Token": accessToken
            },
            validateStatus(status) {
                return status;
            }
        });
    },
    formSelfResolve: () => {
        const url = "/difficult_problems/form_self_resolve_your_problems";
        return axiosClientWithToken.post(url, {
            validateStatus(status) {
                return status;
            }
        });
    },
    updateDataAnswer: (params, accessToken) => {
        const url = "/difficult_problems/update_data_answer";
        return axiosClient.post(url, params, {
            headers: {
                "Access-Token": accessToken
            },
            validateStatus(status) {
                return status;
            }
        });
    },
    getDataAnswer: (accessToken) => {
        const url = "/difficult_problems/get_data_answer";
        return axiosClient.get(url, {
            headers: {
                "Access-Token": accessToken
            },
            validateStatus(status) {
                return status;
            }
        });
    },
    getStatusSolvingProblem: (accessToken) => {
        const url = "/users/status-solving-problems";
        return axiosClient.get(url, {
            headers: {
                "Access-Token": accessToken
            },
            validateStatus(status) {
                return status;
            }
        });
    },
    setStatusSolvingProblem: (firstSolvingProblems, watchVideoDemo) => {
        const url = `users/status-solving-problems?first_solving_problems=${firstSolvingProblems}&watch_video_demo=${watchVideoDemo}`;
        return axiosClientWithToken.post(url, {
            headers: {
                accept: '*/*',
            },
            validateStatus(status) {
                return status;
            }
        });
    },
    watchVideoDemo: (params) => {
        const url = `help_documents/watched-video`;
        return axiosClientWithToken.post(url, params,
          {
            validateStatus(status) {
                return status;
            }
        });
    },
}

export default ProblemSolvingApi;
