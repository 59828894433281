import React from 'react';
import {useSelector} from "react-redux";
import Header from "../components/Header";
import Vietnamese from "../features/QuestionAnswer/vietnamese";
import CheckScorePhq9 from "../repositories/api/checkScorePhq9";
import Sidebar from "../components/Sidebar";
import Footer from "../components/Footer/footer";

function QuestionsAnswers() {
  const auth = useSelector(state => state.auth);
  let scorePhq9 = 0;
  if (auth.authentic) {
    scorePhq9 = CheckScorePhq9();
  }
  return (
    <article id='wrapper'>
      <Header />
      {auth.authentic && <Sidebar score={scorePhq9} />}
      <div id='wrap'>
        <Vietnamese />
      </div>
      <Footer />
    </article>
  )
}

export default QuestionsAnswers;