const TIMES = {
  LOCATION: 'jp-JP',
  TIME_ZONE: 'Asia/Tokyo',
  TIME_CHECK_EMOTION: 60 * 1000,
  DAYS_OF_MONTH: [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31],
  TIME_OF_DAY: 24 * 60 * 60 * 1000,
  ONE_HOURS: 60 * 60 * 1000,
  NEXT_WEEK: 3600 * 24 * 7 * 1000,
  TEN_MINUTES: 10 * 60 * 1000
};
export default TIMES;