import axiosClient from "./axiosClient";

const Phq9Api = {
	getQuestion: (accessToken, params) => {
		const url = "/phq9/show/";
		return axiosClient.get(url, {
			headers: {
				"Access-Token": accessToken
			},
			params,
			validateStatus (status) {
				return status;
			}
		});
	},
	
	saveAnswer: (accessToken, params) => {
		const url = "/phq9/create/";
		return axiosClient.post(url, params,{
			headers: {
				"Access-Token": accessToken
			},
			validateStatus (status) {
				return status;
			}
		});
	},
	averageScore: (accessToken) => {
		const url = "/phq9/average_score";
		return axiosClient.get(url,{
			headers: {
				"Access-Token": accessToken
			},
			validateStatus (status) {
				return status;
			}
		});
	},
};

export default Phq9Api;