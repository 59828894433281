import axiosClient from "./axiosClient";

const ChangeInfoApi = {
  callProfile: (accessToken) => {
    const url = "/users/profile/";
    return axiosClient.get(url, {
      headers: {
        "Access-Token": accessToken
      },
      validateStatus (status) {
        return status;
      }
    });
  },
  changePassword: (accessToken, params) => {
    const url = "/users/update-password/";
    return axiosClient.post(url, params,{
      headers: {
        "Access-Token": accessToken
      },
      validateStatus (status) {
        return status;
      }
    });
  },
  changeInformation: (accessToken, params) => {
    const url = "/users/update-profile/";
    return axiosClient.patch(url, params,{
      headers: {
        "Access-Token": accessToken
      },
      validateStatus (status) {
        return status;
      }
    });
  },
  expertAccountRequire: (accessToken, params) => {
    const url = "/users/request-create-an-expert/";
    return axiosClient.post(url, params,{
      headers: {
        "Access-Token": accessToken
      },
      validateStatus (status) {
        return status;
      }
    });
  }
};

export default ChangeInfoApi;
