import axios from "axios";
import queryString from "query-string";

const token = localStorage.getItem('auth') ? JSON.parse(localStorage.getItem('auth')) : null

const axiosClientWithToken = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    "content-type": "application/json",
    "Access-Token": token && token.access_token,
    paramsSerializer: (params) => queryString.stringify(params),
  },
});

axiosClientWithToken.interceptors.request.use(async (config) => {
  return config;
});

axiosClientWithToken.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return error;
  }
);

export default axiosClientWithToken;
