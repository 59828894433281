import React, {createContext, useEffect, useState} from "react";
import io from "socket.io-client";
import {getAuthProfile} from "../repositories/localStorage/get";

export const SocketContext = createContext(null);

export function SocketProvider(props) {
  const {children} = props;
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    setSocket(io(process.env.REACT_APP_SOCKET_URL, { forceNew: true, transports: ['websocket', 'polling'], query: { user_code: getAuthProfile() ? getAuthProfile().id : "", type: 'user'} }));
  }, []);

  return (
    <SocketContext.Provider value={{socket}}>
      {children}
    </SocketContext.Provider>
  );
}