import axiosClient from "./axiosClient";

const RealisticThinkingApi = {
    getAll: (params, accessToken) => {
        const url = "/realistic-thinking/show";
        return axiosClient.get(url, {
            headers: {
                "Access-Token": accessToken
            },
            params,
            validateStatus (status) {
                return status;
            }
        });
    },
    createPoint: (accessToken, params) => {
        const url = "/realistic-thinking/create";
        return axiosClient.post(url, params,{
            headers: {
                "Access-Token": accessToken
            },
            validateStatus (status) {
                return status;
            }
        });
    },
};

export default RealisticThinkingApi
